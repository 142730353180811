.container {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 0px !important;
}

.statusActive {
  background-color: #d1e0ff !important;
  color: #00359e !important;
  border: 1px solid #00359e !important;
}
.status {
  border-radius: 4px !important;
  border: 1px solid #d0d5dd !important;
  background: #fff !important;
  color: #1d2939 !important;
}
.optionInput {
  cursor: text;
  border: none !important;
  border-bottom: 1px solid #d0d5dd !important;
  outline: none !important;
  border-radius: none !important;
  margin-bottom: 10px !important;
  width: 100% !important;
  max-width: 100% !important;
  display: block !important;
}

.optionInput:focus,
.optionInput:focus {
  border-color: blue;
  box-shadow: none !important;
}
.allTyutElems {
  /* margin-bottom: 36px !important; */
  padding-right: 0 !important;
  border-radius: none !important;
}

.tyutTags {
  padding: 4px 6px 4px 10px;
  border-radius: 8px;
  border: "2px solid #E9D7FE";
  color: "#6941C6";
}

.tagInputContainer {
  border: 1px solid #d1d6de !important;
  border-radius: 6px !important;
  padding: 12px !important;
}
.tagInputContainer::placeholder {
  color: #bfbfbf !important;
}

.viewSOPElemCard {
  background: #fff;
  /* box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16) !important; */
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid rgb(208, 213, 221);
  z-index: 1 !important;
}

.activeCardTag {
  /* background-color: #406aff;
    height: 100%;
    width: 100%;
    position: absolute;
    right: -7px;
    top: 0;
    border-radius: 16px; */
}

.viewQuestionnaireType {
  margin: 24px 36px;
  display: flex;
  justify-content: flex-end;
}

.viewQuestionnaireContent {
  margin: 24px 48px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.viewQuestionnaireAnswerHint > p:first-child {
  font-weight: bolder;
}
.viewQuestionnaireEditBtn {
  margin: 24px 36px;
  margin-top: 0 !important;
  text-align: right;
}

.viewQuestionnaireTypeBox {
  border: 1px solid black;
  padding: 2px 5px;
  width: fit-content;
}

.addSOPElemCard {
  width: 200px !important;
  flex-direction: column !important;
  align-items: flex-start;
  position: fixed !important;
  bottom: 100px !important;
  left: calc(14vw + 24px) !important;
  gap: 12px !important;
  /* border-radius: 16px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16) !important;
    min-height: 84px;
    display: flex;
    justify-content: space-around !important;
    align-items: center !important;
    margin-bottom: 48px;
    gap: 0.75rem;
    flex-wrap: wrap;  */
}

.addSOPElemBtn:hover {
  /* border-color: #406aff; */
  /* color: #406aff; */
  color: #fff !important;
  background-color: #ee6c4d !important;
  border-color: #ee6c4d !important;
}

.addSOPElemBtn {
  background-color: #fff !important;
}
.tyutElemCard {
  /* padding: 24px 36px; */
  /* border-radius: 16px; */
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16) !important;
}
.tyutElemCard > div:first-child {
  border-bottom: none !important;
  /* border-color: transparent !important; */
  margin-bottom: 5px !important;
}
.tyutElemCard > div:nth-child(2) {
  padding: 0 !important;
}

.tyutElemCard > ul {
  border-top: none !important;
}

.optionCheckbox {
  display: inline-flex;
  width: 100%;
}

.optionCheckbox > span:nth-child(2) {
  min-width: 100%;
}

.addOptBtn {
  color: #406aff;
  text-decoration: underline !important;
  text-underline-offset: 4px !important;
  cursor: pointer;
  font-size: 16px;
}

.uploadElem > div:first-child {
  /* border: none !important;
    background: transparent !important; */
}

.uploadElemDropArea {
  border-radius: 8px;
  /* border: 1px solid var(--gray-400, #98a2b3);
    background: #fff; */
  padding: 16px 24px;
  margin-bottom: 24px;
}
.addOptBtn > span {
  text-decoration: underline !important;
}

.addOptBtn:hover {
  color: #406aff !important;
}
.uploadElemDisplayNone > div:first-child {
  display: none;
}

.addMediaElemModal > div:nth-child(2) {
  padding-top: 0 !important;
}

.addMediaElemTabs [class="ant-tabs-tab ant-tabs-tab-active"] {
  background: #ebefff !important;
  padding: 12px;
}
.addMediaElemTabs [class="ant-tabs-tab"] {
  padding: 12px;
}
.addMediaElemTabs > .ant-tabs-tab-active {
  background: red !important;
}

.activeIconTyut:hover {
  font-size: 18px;
}

.activeIconTyut {
  transition: all 0.2s ease;
  font-size: 16px;
  color: #667085;
  cursor: pointer;
}

.sopBtnAdd {
  margin-inline: 4px;
}
.aiAnimation {
  animation: slideme 1s ease-out;
}

@keyframes slideme {
  from {
    transform: scale(0.3);
  }
  to {
    transform: scale(1);
  }
}
@media screen and (max-width: 992px) {
  .addSOPElemCard {
    flex-direction: column !important;
    align-items: flex-start;
    position: fixed !important;
    bottom: 100px !important;
    left: calc(14vw + 12px) !important;
    gap: 12px !important;
    width: 150px !important;
  }
  .sopBtnAdd {
    display: none !important;
  }
  .addSOPElemBtn {
    padding-inline: 6px;
  }
}
