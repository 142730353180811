.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.h1 {
  font-size: 72px;
  line-height: 90px;
  font-weight: 600;
  /* letter-spacing: -0.09rem; */
}
.h2 {
  font-size: 60px;
  line-height: 72px;
  font-weight: 600;
  letter-spacing: -0.09rem;
}

.h3 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 600;
  /* letter-spacing: -0.09rem; */
}
.h4 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  /* letter-spacing: -0.09rem; */
}

.h5 {
  font-size: 30px;
  line-height: 44px;
  font-weight: 600;
  letter-spacing: 0;
}
.h6 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  /* display: flex; */
  /* letter-spacing: -0.03rem; */
  /* font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: 0; */
}

.body1 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0;
}
.body2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0;
}

.body3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
}

.subtext1 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0;
}
.subtext2 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0;
}
.text-left {
  text-align: left;
}
.fileListItem {
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  background: #fff;
  display: flex;
  padding: 3px 4px 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-top: 6px;
  width: fit-content;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .h5 {
    font-size: 24px;
    line-height: 32px;
  }
}
